/** @jsx jsx */
import { jsx } from "theme-ui";
// import { graphql } from "gatsby";
import { Layout, Content } from "maker-ui";
import banner from "../../static/imgs/dollarAdayUsaBanner.png";
import "../../static/styles/dollar.css";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";
import Seo from "../components/seo";
import shakthi from "../../static/imgs/shakthi.png";
import suraj from "../../static/imgs/suraj.png";
import mahima from "../../static/imgs/mahima.png";
import chirag from "../../static/imgs/chirag.png";
import laya from "../../static/imgs/Laya.png";
import emma from "../../static/imgs/Emma.png";
import trevor from "../../static/imgs/Trevor.png";
import plantingSaplings from "../../static/imgs/plantingSaplings.png";
import trainingChildren from "../../static/imgs/trainingChildren.png";
import freeworkshop from "../../static/imgs/freeWorkshop.png";
import ContentHeader from "../components/campaignComponents/ContentHeader";
import OneTimeDonation from "../components/campaignComponents/OneTimeDonation";
import Testimonials from "../components/campaignComponents/Testimonials";
import Slider from "../components/campaignComponents/Slider";
import DonationComp from "../components/campaignComponents/DonateBox";

const impactStories = [
  {
    id: 1,
    title: "Planting Saplings",
    description:
      "Your contribution supports the planting of 10 saplings each month, aiding environmental conservation. These trees absorb carbon dioxide, improve air quality, and provide wildlife habitats. They also beautify urban and rural areas, creating greener, healthier communities. Your support ensures these trees grow and thrive for generations.",
    image: plantingSaplings,
    text: "Forests by Heartfulness",
    color: "#56a51c",
    link: "https://heartfulness.org/forests/",
  },
  {
    id: 2,
    title: "Training Children",
    description:
      "Your donation provides one child with access to the Brighter Minds program, enhancing their cognitive and emotional skills. Thirty dollars a month also supports HELP training for 50 children monthly, fostering an environment where students can excel academically and personally.",
    image: trainingChildren,
    text: "Heartfulness@School",
    color: "#1c76a5",
    link: "https://www.heartfulnessinstitute.org/education/",
  },
  {
    id: 3,
    title: "Free Workshops on Meditation",
    description:
      "Your support enables free workshops teaching meditative practices, promoting mental well-being and stress reduction. These workshops help individuals manage stress, improve focus, and enhance mental health, contributing to a more mindful society.",
    image: freeworkshop,
    text: "Heartfulness@Work",
    color: "#173E5F",
    link: "https://workplace.heartfulnessinstitute.org/",
  },
];

const slides = [
  {
    image: chirag,
    description:
      "Thirty dollars a month is such a small amount for so many of us, in comparison to all the other subscriptions we have. I like to consider this as my monthly 'inner peace' subscription.",
    name: "Chirag",
  },
  {
    image: mahima,
    description:
      "Looking back over the years, I’m overwhelmed with love when I think of the time, guidance, meals, accommodations and friendship the Heartfulness community has given me. This is just one way to share the love.",
    name: "Mahima",
  },
  {
    image: shakthi,
    description:
      "Heartfulness helped me tremendously in studying medicine and preparing to become a doctor. I love to pay it forward in whatever small way I can!",
    name: "Shakti",
  },
  {
    image: suraj,
    description:
      "Heartfulness has transformed my life. I am so grateful to all the instructors who spent countless hours with me, meditating one-on-one and providing me the guidance I needed to go deeper into my practice. It is an honor to be able to pay it forward!",
    name: "Suraj",
  },
  {
    image: emma,
    description:
      "Heartfulness has enriched every aspect of my life because of so much care and attention from the many trainers and volunteers supporting such a rich experience in both the community and in the depths of our hearts. It’s a no-brainer to pay it forward.",
    name: "Emma",
  },
  {
    image: trevor,
    description:
      "Every month when I review expenses, I always stop and smile at the regular Heartfulness donation. It makes me feel good. I truly see it as an investment in the future for people everywhere and am proud to contribute.",
    name: "Trevor",
  },
  {
    image: laya,
    description:
      "Heartfulness has given me a sense of belonging and connection that I cherish. Many of my life long friends come from this community and the practice helps me manage the high pressures I face at college. Contributing a dollar a day is a small way to give back for all I’ve received.",
    name: "Laya",
  },
];

const DollarADayRecurring = () => {
  const defaultAmounts = [
    { value: 30, perday: 1 },
    { value: 60, perday: 2 },
    { value: 150, perday: 5 },
  ];
  const config = {
    currency: "USD",
    donationId: 38,
    titletext: "Dollar a day to HI, USA - Recurring",
    symbol: "$",
    initialAmount: 30,
    meditationPlaceField: false,
  };

  return (
    <div className="campaign-wrapper">
      <Layout theme={theme} options={options}>
        <Seo title="Donate $1 a Day | Heartfulness" />
        <HeaderComp headerCustomLogo="/imgs/heartfulness-white-logo.png" />
        <Content>
          <ContentHeader
            banner={banner}
            title="Donate $1 a Day"
            subTitle="$30 a Month"
          />
          <div className="container">
            <div
              className="row my-5"
              sx={{
                display: "flex",
                "@media (max-width: 768px)": {
                  flexDirection: "column-reverse",
                },
              }}
            >
              <div className="col-lg-6 px-3 my-3">
                <h2
                  sx={{
                    color: "#1f4b72",
                    fontWeight: "700",
                    textAlign: "center",
                    fontSize: "2.5rem",
                    "@media (max-width: 768px)": {
                      fontSize: "2.5rem",
                    },
                  }}
                >
                  Our <span sx={{ color: "rgb(88 153 208)" }}>Mission</span>
                </h2>
                <p className="mt-4" sx={{ fontSize: "1rem !important" }}>
                  Heartfulness will always remain free. It is guided by the core
                  principle that divine wisdom cannot be sold. With over 16,000
                  volunteer instructors and millions of practitioners worldwide,
                  the Heartfulness Institute runs on the enthusiasm and efforts
                  of each one of us. Whether we share a donation, volunteer our
                  time, or simply stay connected and immersed in the light
                  within—every bit helps contribute to the movement
                </p>
                <p className="mt-4" sx={{ fontSize: "1rem !important" }}>
                  For those who have the means and are looking for a way to give
                  back and pay it forward, a dollar a day is a wonderful place
                  to start!
                </p>
              </div>
              <div className="col-lg-6 my-3">
                <DonationComp config={config} defaultAmounts={defaultAmounts} />
              </div>
            </div>
          </div>
          <Slider
            heading={
              <h2 className="pb-5 sliders-heading">
                Impact <span className="highlightText"> Stories</span>{" "}
              </h2>
            }
            impactStories={impactStories}
          />
          <Testimonials slides={slides} />
          <OneTimeDonation
            text="Want to make a one-time donation, or a donation outside the
                  United States?"
          />
        </Content>
        <FooterComp />
      </Layout>
    </div>
  );
};

export default DollarADayRecurring;
